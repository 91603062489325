module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2563eb"},
    },{
      plugin: require('../node_modules/gatsby-theme-mini/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
